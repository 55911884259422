import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

function DynamicBreadcrumb() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleBack = () => {
    navigate(-1);
  };

  const isDashboard = location.pathname === "/dashboard" || location.pathname === "/";

  return (
    <Breadcrumb className="bdc">
      {!isDashboard && (
        <button 
          onClick={handleBack}
          className="btn btn-link p-0 me-2"
          style={{ color: '#0050A4', border: 'none', background: 'none' }}
        >
          <i className="bi bi-arrow-left-circle-fill" style={{fontSize: '24px'}}></i>
        </button>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return (
          <BreadcrumbItem key={name} active={isLast}>
            {isLast ? (
              <span className="text-capitalize" style={{color: `#0050A4`, fontWeight: 600}}>{name}</span>
            ) : (
              <Link to={routeTo} className="text-capitalize" style={{color: `#0050A4`, fontWeight: 600}}>
                {name === "dashboardfile" ? "DASHBOARD" : name}
              </Link>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}

export default DynamicBreadcrumb;
