import React, { useEffect, useState } from "react";
import CancelInspectionModal from "./CancelInspectionModal.js";
import AssignInspectionModal from "./AssignInspectionModal.js";
import ReassignModel from "./ReassignModel.js";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import pdficon from "./img/pdf.png"
import cancelInspectionIcon from "./img/Cancel Inspection.png";
import assignInspectionIcon from "./img/assign.png";
import reassignIcon from "./img/Reassign.png";
import editIcon from "./img/Edit.png";
import archiveIcon from "./img/Archive.png";
import viewInspectionIcon from "./img/Group.png";
import filterIcon from "./img/filter.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../services/ApiCall.js";
import config from "../../config/config.json";
import { Button, Modal } from "react-bootstrap";
import { Button as MuiButton } from '@mui/material'
import Loader from "../CompanyFolder/CompanyList/Loader.js";
import { IoChevronBackCircle } from "react-icons/io5";
import * as XLSX from 'xlsx';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import { Resizable } from 'react-resizable';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CachedIcon from '@mui/icons-material/Cached';
import ArchiveIcon from '@mui/icons-material/Archive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';

const ResizableTableCell = ({ onResize, width, ...props }) => {
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => e.stopPropagation()}
          style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: '5px', cursor: 'col-resize' }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <TableCell {...props} style={{ width, position: 'relative' }} />
    </Resizable>
  );
};

const Inspections = ({ type, status }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [requestData, setRequestData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [expandedRows, setExpandedRows] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendModalIsOpen, setResendModalIsOpen] = useState(false);
  const [assignModalIsOpen, setAssignModalIsOpen] = useState(false);
  const [reassignModelIsOpen, setReassignModalIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionMode, setActionMode] = useState("DELETE");
  const [requestId, setRequestId] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState({
    status: '',
    dateRange: {
      startDate: '',
      endDate: ''
    },
    companyName: '',
    siteCity: '',
    siteState: ''
  });

  const statusMap = {
    0: "New Request",
    1: "In-Progress",
    2: "To-Be-Approved",
    3: "Migrated",
    4: "Approved",
    5: "Waiting for Approval",
    6: "Renewal Scheduled",
    7: "Redo",
    8: "To be Approved",
    9: "Archived",
  };

  const handleAction = () => {
    archiveRequest(requestId);
    setShowDeleteModal(false);
  };

  const handleReassignClick = () => {
    setReassignModalIsOpen(true);
  };

  const handleAssigninspectionClick = () => {
    setAssignModalIsOpen(true);
    console.log()
  };

  const handleResendClick = () => {
    setResendModalIsOpen(true);

    console.log("Resend button clicked");
  };

  const handleCloseReassignModel = () => {
    setReassignModalIsOpen(false);
  };
  const handleCloseAssignModal = () => {
    setAssignModalIsOpen(false);
  };

  const handleCloseResendModal = () => {
    setResendModalIsOpen(false);
  };

  const handleCancelClick = (id) => {
    setModalIsOpen(true);
    setActionMode("DELETE");
    setRequestId(id);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const applyFilters = (data) => {
    return data.filter(item => {
      let matches = true;
      
      if (filters.status && matches) {
        matches = item.status.toString() === filters.status;
      }
      
      if (filters.companyName && matches) {
        matches = item.companyName?.toLowerCase().includes(filters.companyName.toLowerCase());
      }
      
      if (filters.siteCity && matches) {
        matches = item.siteCity?.toLowerCase().includes(filters.siteCity.toLowerCase());
      }
      
      if (filters.siteState && matches) {
        matches = item.siteState?.toLowerCase().includes(filters.siteState.toLowerCase());
      }
      
      if (filters.dateRange.startDate && filters.dateRange.endDate && matches) {
        const itemDate = new Date(item.createdAt);
        const startDate = new Date(filters.dateRange.startDate);
        const endDate = new Date(filters.dateRange.endDate);
        matches = itemDate >= startDate && itemDate <= endDate;
      }
      
      return matches;
    });
  };

  const filteredData = requestData?.length
    ? applyFilters(
        requestData.filter((item) =>
          ["siteId", "id", "araId", "name", "street", "siteAddress", "siteCity", 
           "companyName", "state", "zipcode", "contactName", "contactPhone", 
           "contactEmail"].some((key) =>
            item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      )
    : [];
  console.log(filteredData)

  const sortedData = filteredData.length
    ? filteredData.sort((a, b) => {
      if (!sortColumn) return 0;
      if (a[sortColumn] < b[sortColumn])
        return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn])
        return sortDirection === "asc" ? 1 : -1;
      return 0;
    })
    : [];

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleModeChange = (mode, id) => {
    setActionMode(mode);
    setRequestId(id);
    setShowDeleteModal(true);
  };

  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleViewMore = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      // [id]: true,
      [id]: !prev[id],
    }));
  };

  const archiveRequest = async () => {
    let endpoint = "";

    if (actionMode === "DELETE") {
      endpoint = "cancelRequest";
    } else if (actionMode === "UNARCHIVE") {
      endpoint = "unArchiveRequest";
    } else {
      endpoint = "archiveRequest";
    }

    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}${endpoint}`,
      {},
      { requestId },
      "POST"
    );

    if (isSuccess) {
      getRequestsList();
      handleCloseResendModal();
    }
  };
  const [queryParams, setQueryParams] = useState({});
  const [hashParams, setHashParams] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    setQueryParams(paramsObject);
    const hash = location.hash;
    if (hash) {
      setHashParams(hash.substring(1));

    }
  }, [location]);

  const getRequestsList = async () => {
    setLoading(true)
    const msiId = queryParams.requestId;
    const match = location.pathname.match(/\/all-request\/view\/(\d+)/);
    // if ((match && match[1]) || msiId || status) {


    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getRequestsList`,
      {},
      { type, status, siteId: match && match[1], requestId: msiId || null, accessType: msiId ? 'MAINCONTACT' : null },
      "GET"
    );
    setLoading(false);

    if (isSuccess) {
      setLoading(false)
      if (status == 4) {
        const sortedData = data?.data
          .filter(item => item.approvedDate !== null)
          .sort((a, b) => new Date(b.approvedDate) - new Date(a.approvedDate));
        setRequestData(sortedData);
      } else if (status == 2) {
        const sortedData = data?.data
          .filter(item => item.completedDate !== null)
          .sort((a, b) => new Date(b.completedDate) - new Date(a.completedDate));
        setRequestData(sortedData);
      } else {
        setRequestData(data?.data?.reverse());
      }
    }
    // }
  };

  useEffect(() => {
    const msiId = queryParams.requestId;

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    if (msiId)
      getRequestsList();
  }, [queryParams]);


  useEffect(() => {
    const token = localStorage.getItem("token");
    const match = location.pathname.match(/\/all-request(\/view\/\d+)?/);
    const searchParams = new URLSearchParams(location.search);
    const hideKeywordPresent = searchParams.has("hide");



    if (!token) {
      navigate("/");
    }
    if (match && !hideKeywordPresent) {
      getRequestsList();
    }
  }, [type, status, location]);
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      requestData.map(site => ({
        'Request ID': site?.id,
        "ARA ID": site?.araId,
        "Company Name": site?.companyName,
        "Contact Name": site?.contactName,
        "Contact Email": site?.contactEmail,
        'Site Address': `${site?.siteAddress}, ${site?.siteCity}, ${site?.siteState}, ${site?.siteZipcode}`,
        "Site Admin Name": site?.siteAdminName,
        "Site Admin Email": site?.siteAdminEmail,
        "Site Manager Name": site?.siteManagerName,
        "Site Manager Email": site?.siteManagerEmail,
        "Approval Date": site?.approvedDate ? new Date(site.approvedDate).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).replace(/\//g, '-') : '',
        "Completed Date": site?.completedDate ? new Date(site.completedDate).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).replace(/\//g, '-') : '',
        "Next Inspection Date": site?.scheduledDate ? new Date(site.scheduledDate).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).replace(/\//g, '-') : '',
      }))
    );

    // Define column widths
    worksheet['!cols'] = [
      { wch: 10 }, // Request ID
      { wch: 15 }, // ARA ID
      { wch: 40 }, // Company Name
      { wch: 50 }, // Site Address
      { wch: 30 }, // Main Contact
      { wch: 20 }, // Width for 'Company Name'
      { wch: 20 }, // Width for 'Company Name'
      { wch: 20 }, // Width for 'Company Name'
      { wch: 20 }, // Width for 'Company Name'
      { wch: 15 }, // Width for 'Created Date'
      { wch: 15 }, // Width for 'Created Date'
      { wch: 15 }, // Width for 'Created Date'
      { wch: 15 }, // Width for 'Created Date'
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Request");
    XLSX.writeFile(workbook, "Request.xlsx");
  };
  useEffect(() => {

    const token = localStorage.getItem("token");
    const searchParams = new URLSearchParams(location.search);



    if (!token) {
      navigate("/");
    }

    const hideKeywordPresent = searchParams.has("hide");
    if (!hideKeywordPresent) {
      getRequestsList();
    }

  }, [type, status]);

  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredData.length / entriesPerPage);

    let startPage = currentPage - 1;
    let endPage = currentPage + 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = 3;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - 2 > 0 ? totalPages - 2 : 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`pagination-item ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    return (
      <ul className="pagination">
        <li
          className={`pagination-item ${currentPage === 1 ? "disabled" : ""}`}
          onClick={handlePreviousPage}
        >
          Previous
        </li>
        {pageNumbers}
        <li
          className={`pagination-item ${currentPage === totalPages ? "disabled" : ""
            }`}
          onClick={handleNextPage}
        >
          Next
        </li>
      </ul>
    );
  };

  // Add this function to check if any filters are active
  const hasActiveFilters = () => {
    return filters.status !== '' ||
      filters.dateRange.startDate !== '' ||
      filters.dateRange.endDate !== '' ||
      filters.companyName !== '' ||
      filters.siteCity !== '' ||
      filters.siteState !== '';
  };

  // Add this function to clear all filters
  const clearAllFilters = () => {
    setFilters({
      status: '',
      dateRange: { startDate: '', endDate: '' },
      companyName: '',
      siteCity: '',
      siteState: ''
    });
  };

  return (
    <Box 
      sx={{ 
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box 
        sx={{ 
          flex: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        {/* Controls Section */}
        {!queryParams?.hide && (
          <Stack 
            direction="row" 
            justifyContent="space-between" 
            alignItems="center" 
            spacing={2} 
            sx={{ mb: 3 }}
          >
            {/* Left Controls */}
            <Stack direction="row" spacing={3} alignItems="center">
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel>Show entries</InputLabel>
                <Select
                  value={entriesPerPage}
                  onChange={(e) => setEntriesPerPage(Number(e.target.value))}
                  label="Show entries"
                  size="small"
                >
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={250}>250</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                </Select>
              </FormControl>

              <TextField
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined"
                size="small"
                sx={{ 
                  width: hasActiveFilters() ? '300px' : '400px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#e0e0e0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#0050A4',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#0050A4',
                    },
                  },
                }}
              />
            </Stack>

            {/* Right Controls */}
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton 
                onClick={() => setShowFilterModal(true)}
                sx={{ 
                  border: hasActiveFilters() ? '2px solid #0050A4' : '1px solid #e0e0e0',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 80, 164, 0.04)',
                    border: '1px solid #0050A4',
                  },
                }}
              >
                <FilterListIcon sx={{ color: hasActiveFilters() ? '#0050A4' : 'inherit' }} />
              </IconButton>

              {hasActiveFilters() && (
                <MuiButton 
                  variant="outlined"
                  onClick={clearAllFilters}
                  sx={{ 
                    color: '#0050A4', 
                    borderColor: '#0050A4',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 80, 164, 0.04)',
                      borderColor: '#0050A4',
                    },
                  }}
                >
                  Clear Filters
                </MuiButton>
              )}

              <MuiButton
                variant="contained"
                startIcon={<FileDownloadIcon />}
                onClick={exportToExcel}
                sx={{
                  backgroundColor: '#0050A4',
                  color: '#ffffff',
                  padding: '6px 16px',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  minWidth: '140px',
                  '&:hover': {
                    backgroundColor: '#003C7D',
                  },
                  '&:active': {
                    backgroundColor: '#002B5C',
                  },
                }}
              >
                Export to Excel
              </MuiButton>

              {status !== 9 && (
                <NavLink 
                  to="/add-request" 
                  style={{ 
                    textDecoration: "none",
                    color: 'inherit'
                  }}
                >
                  <MuiButton
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: '#F5A623 !important',
                      color: '#ffffff !important',
                      padding: '6px 16px !important',
                      textTransform: 'none !important',
                      fontSize: '0.875rem !important',
                      '&:hover': {
                        backgroundColor: '#e69516 !important',
                      },
                      '&:active': {
                        backgroundColor: '#d68a13 !important',
                      },
                      '&&': {
                        backgroundColor: '#F5A623',
                        color: '#ffffff',
                      },
                      '&.MuiButton-root': {
                        backgroundColor: '#F5A623',
                        color: '#ffffff',
                      },
                    }}
                  >
                    + Add Request
                  </MuiButton>
                </NavLink>
              )}
            </Stack>
          </Stack>
        )}

        {!loading ? (
          <Paper 
            sx={{ 
              width: '100%', 
              mb: 2,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <TableContainer 
              sx={{ 
                flex: 1,
                zIndex: 0,
                overflowX: 'auto',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: '#555',
                  },
                },
              }}
            >
              <Table stickyHeader>
                <TableHead sx={{ backgroundColor: '#fff' }}>
                  <TableRow>
                    <TableCell 
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1,
                        fontWeight: 'bold',
                        borderBottom: '2px solid #ddd',
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    >
                      ID
                    </TableCell>
                    <TableCell 
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1,
                        fontWeight: 'bold',
                        borderBottom: '2px solid #ddd',
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    >
                      ARA ID
                    </TableCell>
                    <TableCell 
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1,
                        fontWeight: 'bold',
                        borderBottom: '2px solid #ddd',
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    >
                      Company
                    </TableCell>
                    <TableCell 
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1,
                        fontWeight: 'bold',
                        borderBottom: '2px solid #ddd',
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    >
                      Site
                    </TableCell>
                    <TableCell 
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1,
                        fontWeight: 'bold',
                        borderBottom: '2px solid #ddd',
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    >
                      Main Contact
                    </TableCell>
                    {(Number(status) === 4 || type === "ALL") && (
                      <TableCell 
                        sx={{
                          position: 'sticky',
                          top: 0,
                          backgroundColor: '#fff',
                          zIndex: 1,
                          fontWeight: 'bold',
                          borderBottom: '2px solid #ddd',
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                      >
                        Approval Date
                      </TableCell>
                    )}
                    {(Number(status) === 2 || type === "ALL") && (
                      <TableCell 
                        sx={{
                          position: 'sticky',
                          top: 0,
                          backgroundColor: '#fff',
                          zIndex: 1,
                          fontWeight: 'bold',
                          borderBottom: '2px solid #ddd',
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                      >
                        Completed Date
                      </TableCell>
                    )}
                    {(Number(status) === 4 || type === "ALL") && (
                      <TableCell 
                        sx={{
                          position: 'sticky',
                          top: 0,
                          backgroundColor: '#fff',
                          zIndex: 1,
                          fontWeight: 'bold',
                          borderBottom: '2px solid #ddd',
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                      >
                        Next Inspection Date
                      </TableCell>
                    )}
                    {type === "ALL" && (
                      <TableCell 
                        sx={{
                          position: 'sticky',
                          top: 0,
                          backgroundColor: '#fff',
                          zIndex: 1,
                          fontWeight: 'bold',
                          borderBottom: '2px solid #ddd',
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                      >
                        Status
                      </TableCell>
                    )}
                    <TableCell 
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1,
                        fontWeight: 'bold',
                        borderBottom: '2px solid #ddd',
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentEntries.map((item) => (
                    <TableRow 
                      key={item.id}
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#fafafa',
                        },
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                        },
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell>{item?.id}</TableCell>
                      <TableCell>{item?.araId}</TableCell>
                      <TableCell>{item?.companyName}</TableCell>
                      <TableCell>
                        {item?.siteAddress}, {item?.siteCity}, {item?.siteState}
                      </TableCell>
                      <TableCell>
                        {item?.contactName && (
                          <Typography>
                            {item?.contactName}<br/>
                            {item?.contactPhone}
                          </Typography>
                        )}
                      </TableCell>
                      {(Number(status) === 4 || type === "ALL") && (
                        <TableCell>
                          {item?.approvedDate && new Date(item?.approvedDate).toLocaleDateString(
                            "en-US",
                            { year: "numeric", month: "long", day: "numeric" }
                          )}
                        </TableCell>
                      )}
                      {(Number(status) === 2 || type === "ALL") && (
                        <TableCell>
                          {item?.completedDate && new Date(item?.completedDate).toLocaleDateString(
                            "en-US",
                            { year: "numeric", month: "long", day: "numeric" }
                          )}
                        </TableCell>
                      )}
                      {(Number(status) === 4 || type === "ALL") && (
                        <TableCell>
                          {item?.inspectionDate && new Date(item?.inspectionDate).toLocaleDateString(
                            "en-US",
                            { year: "numeric", month: "long", day: "numeric" }
                          )}
                        </TableCell>
                      )}
                      {type === "ALL" && (
                        <TableCell>
                          <div className={`status-${item?.status}`}>
                            {statusMap[item?.status] || "Unknown Status"}
                          </div>
                        </TableCell>
                      )}
                      <TableCell>
                        {status === 9 ? (
                          <IconButton
                            size="small"
                            onClick={() => handleModeChange("UNARCHIVE", item.id)}
                            title="Unarchive"
                          >
                            <ArchiveIcon sx={{ color: '#0050A4' }} />
                          </IconButton>
                        ) : !expandedRows[item.id] ? (
                          <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                            {queryParams?.hide ? (
                              (item?.status == 0 || item?.status == 1) && (
                                <>
                                  <IconButton
                                    size="small"
                                    onClick={handleAssigninspectionClick}
                                    title="Assign Inspection"
                                  >
                                    <AssignmentIndIcon sx={{ color: '#0050A4' }} />
                                  </IconButton>
                                  <AssignInspectionModal
                                    getRequestsList={getRequestsList}
                                    isOpen={assignModalIsOpen}
                                    item={item}
                                    onRequestClose={handleCloseAssignModal}
                                  />
                                </>
                              )
                            ) : (
                              <NavLink
                                to="/request-form"
                                state={{ requestId: item.id, requestData: item }}
                                style={{ textDecoration: "none" }}
                              >
                                <IconButton size="small" title="Edit Form">
                                  <EditIcon sx={{ color: '#0050A4' }} />
                                </IconButton>
                              </NavLink>
                            )}

                            {!queryParams?.hide && (
                              <>
                                <NavLink
                                  to="/view-pdf-report"
                                  state={{ requestId: item.id, requestData: item }}
                                  style={{ textDecoration: "none" }}
                                >
                                  <IconButton size="small" title="View PDF">
                                    <PictureAsPdfIcon sx={{ color: '#0050A4' }} />
                                  </IconButton>
                                </NavLink>

                                <Button
                                  variant="outlined"
                                  onClick={() => handleViewMore(item.id)}
                                  startIcon={<VisibilityIcon />}
                                  sx={{
                                    color: '#0050A4',
                                    borderColor: '#0050A4',
                                    textTransform: 'none',
                                    padding: '6px 12px',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 80, 164, 0.04)',
                                      borderColor: '#0050A4',
                                    },
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    height: '32px',
                                    whiteSpace: 'nowrap',
                                    lineHeight: 1,
                                    minWidth: 'unset'
                                  }}
                                >
                                  {expandedRows[item.id] ? 'View Less' : 'View More'}
                                </Button>
                              </>
                            )}
                          </Stack>
                        ) : (
                          <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                            {queryParams?.hide ? (
                              (item?.status == 0 || item?.status == 1) && (
                                <>
                                  <IconButton
                                    size="small"
                                    onClick={handleAssigninspectionClick}
                                    title="Assign Inspection"
                                  >
                                    <AssignmentIndIcon sx={{ color: '#0050A4' }} />
                                  </IconButton>
                                  <AssignInspectionModal
                                    getRequestsList={getRequestsList}
                                    isOpen={assignModalIsOpen}
                                    item={item}
                                    onRequestClose={handleCloseAssignModal}
                                  />
                                </>
                              )
                            ) : (
                              <NavLink
                                to="/request-form"
                                state={{ requestId: item.id, requestData: item }}
                                style={{ textDecoration: "none" }}
                              >
                                <IconButton size="small" title="Edit Form">
                                  <EditIcon sx={{ color: '#0050A4' }} />
                                </IconButton>
                              </NavLink>
                            )}

                            {!queryParams?.hide && (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => handleCancelClick(item.id)}
                                  title="Cancel Inspection"
                                >
                                  <CancelIcon sx={{ color: '#0050A4' }} />
                                </IconButton>
                                <CancelInspectionModal
                                  isOpen={modalIsOpen}
                                  onRequestClose={handleCloseModal}
                                  onConfirm={archiveRequest}
                                />

                                {(item?.status == 0 || item?.status == 1) && (
                                  <>
                                    <IconButton
                                      size="small"
                                      onClick={handleAssigninspectionClick}
                                      title="Assign Inspection"
                                    >
                                      <AssignmentIndIcon sx={{ color: '#0050A4' }} />
                                    </IconButton>
                                    <AssignInspectionModal
                                      getRequestsList={getRequestsList}
                                      isOpen={assignModalIsOpen}
                                      item={item}
                                      onRequestClose={handleCloseAssignModal}
                                    />
                                  </>
                                )}

                                <IconButton
                                  size="small"
                                  onClick={handleReassignClick}
                                  title="Reassign"
                                >
                                  <CachedIcon sx={{ color: '#0050A4' }} />
                                </IconButton>
                                <ReassignModel
                                  isOpen={reassignModelIsOpen}
                                  onRequestClose={handleCloseReassignModel}
                                />

                                <IconButton
                                  size="small"
                                  onClick={() => handleModeChange("EDIT", item.id)}
                                  title="Archive"
                                >
                                  <ArchiveIcon sx={{ color: '#0050A4' }} />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  onClick={() => handleViewMore(item.id)}
                                  title="View Less"
                                  sx={{ color: 'green' }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </>
                            )}
                          </Stack>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            <TablePagination
              component="div"
              count={filteredData.length}
              rowsPerPage={entriesPerPage}
              page={currentPage - 1}
              onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
              onRowsPerPageChange={(event) => {
                setEntriesPerPage(parseInt(event.target.value, 10));
                setCurrentPage(1);
              }}
              sx={{
                borderTop: '1px solid #ddd',
                backgroundColor: '#fff',
              }}
            />
          </Paper>
        ) : (
          <Loader />
        )}

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {actionMode === "DELETE"
                ? "Delete Confirmation"
                : actionMode === "UNARCHIVE"
                  ? "Un-Archive Confirmation"
                  : "Archive Confirmation"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to{" "}
            {actionMode === "DELETE"
              ? "delete"
              : actionMode === "UNARCHIVE"
                ? "Un-Archive"
                : "archive"}{" "}
            this Request?
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Button
              variant="secondary"
              style={{
                color: "#0050A4",
                border: "2px solid #0050A4",
                backgroundColor: "white",
                fontWeight: "bold",
              }}
              onClick={() => setShowDeleteModal(false)}
            >
              Close
            </Button>
            <Button
              variant="danger"
              style={{
                color: "white",
                backgroundColor: "#0050A4",
                border: "1px solid #0050A4",
              }}
              onClick={handleAction}
            >
              {actionMode === "DELETE"
                ? "Delete"
                : actionMode === "UNARCHIVE"
                  ? "Un-Archive"
                  : "Archive"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              Filter Options 
              {hasActiveFilters() && (
                <span className="active-filters-badge">
                  {Object.values(filters).filter(val => 
                    typeof val === 'string' ? val !== '' : 
                    Object.values(val).some(v => v !== '')
                  ).length} active
                </span>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="filter-form">
              {type === "ALL" && (
                <div className="mb-3">
                  <label className="form-label">Status</label>
                  <select 
                    className="form-select"
                    value={filters.status}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                  >
                    <option value="">All</option>
                    {Object.entries(statusMap).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    ))}
                  </select>
                </div>
              )}
              
              <div className="mb-3">
                <label className="form-label">Date Range</label>
                <div className="d-flex gap-2">
                  <input
                    type="date"
                    className="form-control"
                    value={filters.dateRange.startDate}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      dateRange: { ...prev.dateRange, startDate: e.target.value }
                    }))}
                  />
                  <input
                    type="date"
                    className="form-control"
                    value={filters.dateRange.endDate}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      dateRange: { ...prev.dateRange, endDate: e.target.value }
                    }))}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={filters.companyName}
                  onChange={(e) => handleFilterChange('companyName', e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className="form-control"
                  value={filters.siteCity}
                  onChange={(e) => handleFilterChange('siteCity', e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">State</label>
                <input
                  type="text"
                  className="form-control"
                  value={filters.siteState}
                  onChange={(e) => handleFilterChange('siteState', e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="secondary" 
              onClick={() => {
                setFilters({
                  status: '',
                  dateRange: { startDate: '', endDate: '' },
                  companyName: '',
                  siteCity: '',
                  siteState: ''
                });
                setShowFilterModal(false);
              }}
            >
              Clear Filters
            </Button>
            <Button 
              variant="primary" 
              onClick={() => setShowFilterModal(false)}
            >
              Apply Filters
            </Button>
          </Modal.Footer>
        </Modal>
      </Box>
    </Box>
  );
};

export default Inspections;
